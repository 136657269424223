import React from 'react';

const NeonModal = ({details, button}) => {

  const {title, body, note} = details;

  return (
    <div className={'neon-modal'}>
      { title && <h1 className={'neon-modal__title'} dangerouslySetInnerHTML={{ __html: title }} /> }
      { body && <p className={'neon-modal__body'} dangerouslySetInnerHTML={{ __html: body }} /> }
      { note && <p className={'neon-modal__note'} dangerouslySetInnerHTML={{ __html: note }} /> }
      {
        button &&
        <div className={'neon-modal__actions'}>
          <a className={'button button--primary'} href={button.href}>{button.text}</a>
        </div>
      }
    </div>
  );
};

export default NeonModal;