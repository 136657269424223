import React from 'react';

const NewsItem = ({date, title, body, link, important}) => {
  return (
    <div className={!important ? 'news' : 'news news--important'}>
      { date && <div className={'news__date'}>{date}</div> }
      { title && <h2 className={'news__title'}>{title}</h2> }
      { body && <p className={'news__body'}>{body}</p> }
      { link && <a className={'link news__link'} href={link.href} target={'_blank'}>{link.text}</a> }
    </div>
  );
};

export default NewsItem;