import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

import svg_bezpieczenstwo from '../../resources/img/svg/icon_bezpieczenstwo_24px.svg';
import svg_pomoc from '../../resources/img/svg/icon_pomoc_24px.svg';
import svg_kontakt from '../../resources/img/svg/icon_mail_24px.svg';

let year = new Date().getFullYear();
const Footer = ({links}) => {

  const renderLink = ({titles, link_urls, id}) => {

    const title = titles['title']['_text'].trim();
    const url = link_urls['link_url']['_text'].trim();
    const key = id['_text'].trim();

    let icon = '';    // todo - męczyć backend o wystawienie ikon ?
    if (title === 'Bezpieczeństwo') icon = <img src={svg_bezpieczenstwo}/>;
    if (title === 'Pomoc') icon = <img src={svg_pomoc}/>;
    if (title === 'Kontakt') icon = <img src={svg_kontakt}/>;

    return (<a key={key} className={'footer__item footer__item--link'} href={url}>{icon}{title}</a>)
  }

  return (
    <footer className={'footer'}>
      <div className={'footer__divider'}></div>
      <Container>
        <Row>
          <Col>
            <div className={'footer__main'}>
              <div className={'footer__left'}>
                <span className={'footer__item footer__item--gray'}>&copy; {year} PKO Bank Polski.</span>
                <span className={'footer__item footer__item--gray'}>Kod BIC (Swift): BPKOPLPW</span>
                <a className={'footer__item footer__item--gray'} href={'https://www.pkobp.pl/polityka-prywatnosci/'}>Polityka prywatności</a>
              </div>
              <div className={'footer__right'}>
                {links.map(i => renderLink(i))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;