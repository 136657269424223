import * as React from "react"
import {Container, Row, Col} from 'react-bootstrap';

import svg_ikona_x from '../../resources/img/svg/icon_x_gray.svg'

const Curtain = ({icon, link, body, buttons, handleClickClose}) => {

  const renderMobileButton = () => {
    if(!buttons){
      return ''
    }

    const button = buttons.list.find(b => b.media === 'mobile');

    if (!button) {
      return ''
    }

    return (
      <div className="curtain__download"><a className={'link'} href={button.href} target={'_blank'}>{button.text}</a>
      </div>
    )
  }

  const renderDesktopButton = () => {
    if(!buttons){
      return ''
    }

    const button = buttons.list.find(b => b.media === 'desktop');

    if (!button) {
      return ''
    }

    return (
      <a className={'button button--light button--small curtain__more'} href={button.href} target={'_blank'}>{button.text}</a>
    )
  }

  return (
    <div className="curtain__wrapper">
      <Container>
        <Row>
          <Col>
            <div className="curtain">
              <div className="curtain__description">
                {
                  icon && <img src={icon.src} className="curtain__logo" alt={icon.alt}/>
                }
                {
                  link && <a href={link.href} className="link curtain__link" target={'_blank'}>{link.text}</a>
                }
                {
                  body && <span className="curtain__text">{body}</span>
                }
                {renderDesktopButton()}
              </div>
              <button className="button button--none curtain__close" onClick={handleClickClose}>
                <img src={svg_ikona_x} alt={'Ukryj'}/>
              </button>
              {renderMobileButton()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Curtain;