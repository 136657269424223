import * as React from "react"
import {useEffect, useLayoutEffect, useState} from "react";
import {Helmet} from "react-helmet"
import {Container, Row, Col} from 'react-bootstrap';

import "../styles/styles.scss";

import Curtain from '../components/curtain';
import Header from "../components/header";
import LoginForm from "../components/loginform";
import Neon from "../components/neon";
import NewsItem from "../components/news";
import Footer from "../components/footer";
import Modal from "../components/_atoms/modal";
import {getConfig, getErrorCodes, getService} from "../services/Service";
import {xmltojson} from "../helpers/xmltojson";
import NeonModal from "../components/neonmodal";
import Sidebar from "../components/sidebar";
import ResetForm from "../components/resetform";

const IndexPage = () => {

  const [footerLinks, setFooterLinks] = useState([]);
  const [news, setNews] = useState([]);
  const [curtain, setCurtain] = useState(null);
  const [curtainVisibility, setCurtainVisibility] = useState(false);
  const [neon, setNeon] = useState(null);
  const [neonModalVisibility, setNeonModalVisibility] = useState(false);
//  const [errorCode, setErrorCode] = useState(null);
  const [withSidebar, setWithSidebar] = useState(false);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [loginIsMounted, setLoginIsMounted] = useState(false);
  const [resetIsMounted, setResetIsMounted] = useState(false);
  const [loginGoBack, setLoginGoBack] = useState(false);

  useLayoutEffect(() => {

    observeInsertDOM();
    observeRemovedDOM();

    //   detectErrorFromUrl();
    loadEPMScripts();
  }, [])

  useLayoutEffect(() => {
    if (scriptLoaded) loadEpmNextLogin();
  }, [scriptLoaded]);

  useEffect(() => {
    if (loginIsMounted) {
      setGoToResetPasswordListener();
      setInputFocus('login-email-input');

    } else if (!loginIsMounted && withSidebar) {
      loadEpmNextReset();
    }
  }, [loginIsMounted, withSidebar])

  useEffect(() => {
    if (resetIsMounted) {
      setGoToLoginListener();
      setInputFocus('pesel-input');

    } else if (!resetIsMounted && loginGoBack) {
      loadEpmNextLogin();
    }

  }, [resetIsMounted, loginGoBack])

  useEffect(() => {
    loadConfig();
  }, [])


  const observeInsertDOM = () => {
    document.body.addEventListener('DOMNodeInserted', e => {
      if (e.relatedNode === document.getElementById('epm-next-login')) {
        setLoginIsMounted(true);
      }
      if (e.relatedNode === document.getElementById('epm-next-reset-password')) {
        setResetIsMounted(true);
      }
    });
  }

  const observeRemovedDOM = () => {

    document.body.addEventListener('DOMNodeRemoved', e => {
      if (e.relatedNode === document.getElementById('epm-next-login')) {
        setLoginIsMounted(false);
      }
      if (e.relatedNode === document.getElementById('epm-next-reset-password')) {
        setResetIsMounted(false);
      }
    });
  }

  const loadEPMScripts = () => {

    const v = new Date().toJSON();
    const src = `epm-next-login/main.js?v=${v}`

    const elem = document.createElement('script');
    elem.setAttribute('type', 'text/javascript')
    elem.setAttribute('src', src)
    document.head.appendChild(elem)

    elem.onload = function () {
      setScriptLoaded(Boolean(document.head.querySelector(`script[src="${src}"]`)));
    }
  }

  const loadEpmNextLogin = () => {

    window.EpmNextLogin.Api.authentication.login.mount();
    if (loginGoBack) setLoginGoBack(false);
  }

  const loadEpmNextReset = () => {
    window.EpmNextLogin.Api.authentication.resetPassword.mount();
  }

  const setGoToResetPasswordListener = () => {
    const elem = document.getElementById('reset-password-link')
    elem.addEventListener('click', () => onClickResetPassword())
  }

  const onClickResetPassword = () => {
    window.EpmNextLogin.Api.authentication.login.unmount();
    setWithSidebar(true);
  }

  const setGoToLoginListener = () => {
    const elem = document.getElementById('go-back-login-link')
    elem.addEventListener('click', () => onClickBackToLogin())
  }

  const onClickBackToLogin = () => {
    window.EpmNextLogin.Api.authentication.resetPassword.unmount();
    setWithSidebar(false);
    setLoginGoBack(true);
  }


  const setInputFocus = (inputId) => {
    const $input = document.getElementById(inputId);
    if($input)
      $input.focus();
  }

  /*  const findErrorCode = async (err) => {
      const codes = await getErrorCodes();

      if (codes && codes.errors) {
        if (Object.keys(codes.errors).indexOf(err) > -1)
          setErrorCode({code: err, message: codes.errors[err]})
      } else {
        setErrorCode(null)
      }
    }

    const detectErrorFromUrl = () => {
      const params = getUrlParams();

      if (params) {
        const errParam = params.find(p => Object.keys(p).indexOf('errorCode') > -1)
        if (errParam)
          findErrorCode(errParam.errorCode)
      }
    }

    const getUrlParams = () => {
      if (window.location.search.length === 0) {
        return null;
      } else {
        // change ?param=xxx&param2=xxx to [{ param:xxx }, { param2:xxx }]
        const searchParams = window.location.search.substr(1, window.location.search.length);
        return Array.from(searchParams.split('&'), (item) => item.split('=')).map(item => {
          return {[item[0]]: item[1]}
        });
      }
    }*/

  const loadConfig = async () => {

    const cfg = await getConfig();

    if (cfg) {

      const footerLinksCfg = cfg.find(i => i.key === 'CMS_FOOTER_LINKS_XML');
      const placementsCfg = cfg.find(i => i.key === 'CMS_PLACEMENTS_BASE64');

      if (footerLinksCfg) getFooterLinks(footerLinksCfg.url)
      if (placementsCfg) getPlacements(placementsCfg.url, placementsCfg.placements)

      if (!footerLinksCfg || !placementsCfg) console.error('Nie można pobrać konfiguracji footerLinksCfg lub placementsCfg z configa')
    }
  }

  const getFooterLinks = async (url) => {

    const response = await getService(url);

    if (response) {
      const json = JSON.parse(xmltojson(response?.replace(/(\r\n|\n|\r)/gm, "")))
      const response = json['response'];
      const links = response?.['link-list']['link'];

      if (links) {
        links.sort((x, y) => {
          if (x['order']['_text'] < y['order']['_text']) return -1
          else return 1
        })

        setFooterLinks(links);
      } else {
        console.error('Nie istnieją linki w link-list w JSON')
      }

    }

  }

  const getPlacements = async (url, placements) => {

    const response = await getService(url, 'text');

    if (response && response.indexOf('<!DOCTYPE') < 0) {
      //  try {
      // const text = await response.text();
      // if (text) {

      const json = JSON.parse(atob(response))

      for (let item of placements) {

        if (item.key === 'NEON') {
          if (json[item.placement]) {
            setNeon(json[item.placement]['data']['list'][0]);
          } else {
            console.error(`Nie istnieje placement o nazwie ${item.placement} w JSON`)
          }
        }

        if (item.key === 'NEWS') {
          if (json[item.placement]) {
            setNews(json[item.placement]['data']['list']);
          } else {
            console.error(`Nie istnieje placement o nazwie ${item.placement} w JSON`)
          }
        }

        if (item.key === 'CURTAIN') {
          if (json[item.placement]) {
            setCurtain(json[item.placement]['data']['list'][0]);
            setCurtainVisibility(true);
          } else {
            console.error(`Nie istnieje placement o nazwie ${item.placement} w JSON`)
          }
        }

      }

      //   }
      //  } catch (err) {
      //   console.error('Cannot get content of blob')
      // }

    }
  }

  return (
    <main className={'site'}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>PKO supermakler</title>
      </Helmet>
      {
        curtainVisibility && <Curtain {...curtain} handleClickClose={() => setCurtainVisibility(false)}/>
      }
      <div className={withSidebar ? 'main main--with-sidebar' : 'main'}>
        <div className={'main__wrapper'}>
          {
            withSidebar &&
            <Header/>
          }
          <Sidebar/>
          <div className={'main__content'}>
            <Header/>
            {
              withSidebar &&
              <Container>
                <Row>
                  <Col>
                    <ResetForm/>
                  </Col>
                </Row>
              </Container>
            }
            {
              !withSidebar &&
              <Container>
                <Row>
                  <Col lg={6} xl={6}>
                    <LoginForm/>
                  </Col>
                  <Col lg={6} xl={6} className={'main__right-column'}>
                    <div>
                      <Neon {...neon} handleClickNeon={() => setNeonModalVisibility(true)}/>
                    </div>
                  </Col>
                </Row>
                <div className={'main__bottom'}>
                  <Row>
                    {
                      news.map((item, index) =>
                        <Col xl={4} lg={4} md={4} key={index}>
                          <NewsItem {...item} />
                        </Col>
                      )
                    }
                  </Row>
                </div>
              </Container>
            }
          </div>
        </div>
      </div>
      <Footer links={footerLinks}/>
      {
        (neonModalVisibility && neon) &&
        <Modal handleClickClose={() => setNeonModalVisibility(false)}>
          <NeonModal {...neon} />
        </Modal>
      }
      {/*      {
        (errorCode !== null) &&
        <Modal handleClickClose={() => setErrorCode(null)}>
          <p>Wystąpił błąd: {errorCode.message}</p>
          <small>Kod błędu: {errorCode.code}</small>
        </Modal>
      }*/}
    </main>
  )
}

export default IndexPage
