import axios from "axios";

export const getConfig = async () => {

  try {
    const cfg = await axios.get('./cms-cfg.json');

    if (cfg.data && JSON.stringify(cfg.data) !== '{}') {
      return cfg.data
    } else {
      console.error('Cannot get content from cfg file')
      return null;
    }
  } catch (err) {
    console.error('Cannot get cfg file ', err)
    return null;
  }

}

export const getErrorCodes = async () =>{
    try {
    const file = await axios.get('./assets/epm-next-login/locales/pl/translation.json');

    if (file.data && JSON.stringify(file.data) !== '{}') {
      return file.data
    } else {
      console.error('Cannot get content from translation file')
      return null;
    }
  } catch (err) {
    console.error('Cannot get translation file ', err)
    return null;
  }
}

export const getService = async (url, responseType = 'text') => {
  /// try {

  const response = await axios({
    method: 'GET',
    url,
    responseType
  });

 // console.log(response)

  return response.data;

  // todo - poogarniać tak, jak powinno być

  /*
      if (response.data) {
        return response.data
      } else {
        console.error('Cannot get content from ', url)
        return null;
      }
    } catch (err) {
      console.error('Cannot reach get ', url, err)
      return null;
    }*/
}