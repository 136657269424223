import React from 'react';
import svg_padlock from './../../resources/img/svg/icon_haslo.svg'

const Sidebar = () => {
  return (
    <div className={'sidebar'}>
      <div className={'sidebar__top'}>
        <div>
          <img src={svg_padlock} alt={'Ikona kłódka'}/>
        </div>
        <h1>
          Odzyskaj hasło podając swój PESEL i numer telefonu, który podałeś w Biurze Maklerskim
        </h1>
        <h6>
          Na zarejestrowany w Biurze Maklerskim adres e-mail otrzymasz Twój login oraz link aktywacyjny. Użyj go, aby otrzymać jednorazowe hasło do logowania w wiadomości SMS.
        </h6>
      </div>
      <div className={'sidebar__bottom'}>
        <h6>
          Potrzebujesz pomocy? <a href={'https://www.bm.pkobp.pl/o-nas/kontakt/'}>Skontaktuj się z nami.</a>
        </h6>
      </div>
    </div>
  );
};

export default Sidebar;