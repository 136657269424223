import React, {useEffect} from 'react';

const Overlay = ({handleClickClose}) => {

  useEffect(() => {
    document.body.classList.add('disable-scroll');

    return () => document.body.classList.remove('disable-scroll');
  }, [])

  return (
    <div className={'overlay'} onClick={handleClickClose}></div>
  );
};

export default Overlay;