import React from 'react';

const Neon = ({title, body, button, image, details, handleClickNeon}) => {

  const isSetDetails = () => {
    return details && (details.title || details.body)
  }

  const onClickNeon = () => {

    if (isSetDetails()) {
      handleClickNeon();
    }

  }

  return (
    <div className={'neon'}>
      {
        image &&
        <div className={'neon__image'} onClick={onClickNeon}>
          <img src={image.src}/>
        </div>
      }
      <div className={'neon__description'}>
        { title && <h1 className={'neon__title'} onClick={onClickNeon} dangerouslySetInnerHTML={{__html: title}}/>}
        { body && <p className={'neon__paragraph'} onClick={onClickNeon} dangerouslySetInnerHTML={{__html: body}}/> }
        <div className={'neon__actions'}>
          {
            (isSetDetails()) ?
              <a className={'button button--small button--gold neon__button--cta'}
                 onClick={onClickNeon}>{button ? button.text : 'Pokaż'}</a>
              :
              button ?
                <a className={'button button--small button--gold neon__button--cta'}
                   href={button.href}>{button.text}</a>
                :
                ''
          }
          {/*<a className={'button button--small button--link link neon__button--link'} href={'#'}>Link</a>*/}
        </div>
      </div>
    </div>
  );
};

export default Neon;