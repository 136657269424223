import React from 'react';

const ResetForm = () => {
  return (
    <div className={'reset-form'}>
      <div id="epm-next-reset-password"></div>
    </div>
  );
};

export default ResetForm;