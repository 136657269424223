import React from 'react';
import Overlay from "../overlay";

import svg_close from '../../../resources/img/svg/icon_x_navy_24px.svg'

const Modal = ({children, handleClickClose}) => {
  return (
    <>
      <div className={'modal'}>
        <div className={'modal__top'}>
          <button className={'button button--none modal__close'} onClick={handleClickClose}>
            <img src={svg_close}/>
          </button>
        </div>
        <div className={'modal__content'}>
          {children}
        </div>
      </div>
      <Overlay handleClickClose={handleClickClose}/>
    </>
  );
};

export default Modal;