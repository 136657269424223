import React, {useLayoutEffect, useState} from 'react';
import {Row,Col} from "react-bootstrap";

const LoginForm = () => {
  const [isCapslockEndabled, setCapslockEndabled] = useState(false)

    useLayoutEffect(() => {

    document.body.addEventListener('keypress', e=>{
      setCapslockEndabled(e.getModifierState('CapsLock'))
    })
      }, [])
  return (
    <div className={'login-form'}>
      <Row>
        <Col xl={{span:8, offset:2}} lg={{span:10, offset:1}}>
          <div id="epm-next-login"></div>
            {isCapslockEndabled && (<div className={'form__message margin-bottom-0'}>Uwaga! Caps Lock jest włączony</div>)}
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;